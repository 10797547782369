import React from 'react'
import clsx from 'clsx'
import {Field, Form, FormSpy} from 'react-final-form'
import { email, requiredAll, password } from '../../form/validation';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid'
import Typography from '../../components/Typography'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup';
import FormFeedback from '../../form/FormFeedback'
import { formError } from '../../form/utils'
import { TextField } from 'mui-rff'
import PublicLayout from "../../layout/PublicLayout";
import RegisterIcon from '@material-ui/icons/ExitToApp';
import CheckIcon from '@material-ui/icons/Check';
import { promiseListener } from "../../redux/store";
import MakeAsyncFunction from "react-redux-promise-listener";
import Link from "@material-ui/core/Link";
import {Link as RouterLink} from "react-router-dom";

import {
  REGISTER,
  REGISTER_SUCCESS,
  REGISTER_FAIL
} from "../../redux/modules/base"
import {LOGIN_ROUTE} from "../../const";
import Box from "@material-ui/core/Box";




const styles = theme => ({
  root: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'stretch',
  },
  form: {
    flex: 1,
  },
  buttonGrid: {
    width: '100%',
    marginBottom: 20,
    flex: 1,
    flexDirection: 'row',
    alignItems: 'stretch',
  },
  buttonGroup: {
    flexGrow: 1
  },
  buttonActive: {
    backgroundColor: theme.palette.primary.main,
  },
  buttonNotActive: {
    backgroundColor: theme.palette.secondary.main,
  },
  submitButton: {
    marginTop: theme.spacing(2)
  },
  header: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    textAlign: 'center'
  },
  link: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
    textAlign: 'center',
    // color: theme.palette.secondary.dark
  },
  feedback: {
    width: "100%",
    display: "inline-flex",
    justifyContent: "center"
  }
})

const validate = values => {
  let errors = {}
  if (values.account_type === 'business') {
    errors = requiredAll(['first_name', 'last_name', 'email', 'password', 'company_name', 'phone_number'], values)
  } else {
    errors = requiredAll(['first_name', 'last_name', 'email', 'password'], values)
  }


  if (!errors.email) {
    const emailError = email(values.email, values);
    if (emailError) {
      errors.email = email(values.email, values);
    }
  }

  if (!errors.password) {
    const passwordError = password(values.password)
    if (passwordError) {
      errors.password = passwordError
    }
  }
  return errors;
};

const mutatorsEx = {
  setBusiness: (args, state, utils) => {
    utils.changeValue(state, 'account_type', () => 'business')
  },
  setPersonal: (args, state, utils) => {
    utils.changeValue(state, 'account_type', () => 'personal')
  }
}

function Register ({classes}) {
  return (
    <PublicLayout>
      <div className={classes.root}>
        <MakeAsyncFunction
          listener={promiseListener}
          start={REGISTER}
          resolve={REGISTER_SUCCESS}
          reject={REGISTER_FAIL}
          getPayload={(a) => (formError(a.result))}
        >
          {onSubmit => (
            <Form
              onSubmit={onSubmit}
              validate={validate}
              mutators={{...mutatorsEx}}
              initialValues={{account_type: 'personal'}}
              render={({handleSubmit, submitting, pristine, values, form,submitError}) => (
                <form onSubmit={handleSubmit} className={classes.form} noValidate>
                  <div className={classes.link}>
                    <Link component={RouterLink} to={LOGIN_ROUTE}>
                      Already Registered? Sign In here!
                    </Link>
                  </div>
                  <div className={classes.border} />
                  <Typography variant="body1" className={classes.header}>
                    Please select the account type:
                  </Typography>
                  <ButtonGroup
                    className={classes.buttonGrid}
                    variant="contained"
                    size="large"
                    color="primary"
                    aria-label="large contained primary button group"
                  >
                    <Button
                      className={clsx(classes.buttonGroup, values.account_type !== 'personal' && classes.buttonNotActive)}
                      onClick={form.mutators.setPersonal}
                      startIcon={values.account_type === 'personal' && <CheckIcon />}
                    >
                      Personal
                    </Button>
                    <Button
                      className={clsx(classes.buttonGroup, values.account_type !== 'business' && classes.buttonNotActive)}
                      onClick={form.mutators.setBusiness}
                      startIcon={values.account_type === 'business' && <CheckIcon />}
                    >
                      Business
                    </Button>
                  </ButtonGroup>
                  <div>
                    <Field
                      name="account_type"
                      component="input"
                      type="hidden"
                    />
                  </div>
                  {values.account_type &&
                  <React.Fragment>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          autoFocus
                          variant="outlined"
                          autoComplete="fname"
                          fullWidth
                          label="First name"
                          name="first_name"
                          required
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          variant="outlined"
                          autoComplete="lname"
                          fullWidth
                          label="Last name"
                          name="last_name"
                          required
                        />
                      </Grid>
                    </Grid>
                    {values.account_type === 'business' &&
                    <React.Fragment>
                      <TextField
                        disabled={submitting}
                        variant="outlined"
                        fullWidth
                        label="Business Name"
                        margin="normal"
                        name="company_name"
                        required
                      />
                      <TextField
                        disabled={submitting}
                        variant="outlined"
                        fullWidth
                        label="Phone Number"
                        margin="normal"
                        name="phone_number"
                        required
                      />
                    </React.Fragment>}
                    <TextField
                      autoComplete="email"
                      variant="outlined"
                      disabled={submitting}
                      fullWidth
                      label="Email"
                      margin="normal"
                      name="email"
                      required
                    />
                    <TextField
                      fullWidth
                      disabled={submitting}
                      variant="outlined"
                      required
                      name="password"
                      autoComplete="current-password"
                      label="Password"
                      type="password"
                      margin="normal"
                    />
                    <Box className={classes.feedback}>
                      <FormFeedback error>
                        {submitError}
                      </FormFeedback>
                    </Box>
                    <Button
                      type="submit"
                      variant="contained"
                      className={clsx(classes.submitButton)}
                      disabled={submitting || pristine || !values.account_type}
                      color="primary"
                      size="large"
                      startIcon={<RegisterIcon />}
                      fullWidth
                    >
                      {submitting ? 'In progress…' : 'Sign Up'}
                    </Button>
                  </React.Fragment>}
                </form>
              )}
            />)
          }
        </MakeAsyncFunction>
      </div>
    </PublicLayout>
  )
}

export default withStyles(styles)(Register)