import React from 'react'


import PrivateLayout from "../../layout/PrivateLayout";

import PersonalWizard from "./PersonalWizard";
import Verification from "./Verification";

const styles = theme => ({
  root: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'stretch',
  },

})




function Personal ({info, config, user}) {
  return (
    <PrivateLayout>
      {info.isCompleted
      ? <Verification info={info} config={config} user={user} />
      : <PersonalWizard info={info} />
      }
    </PrivateLayout>
  )
}

export default Personal