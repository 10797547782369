import React from 'react'
import { Field } from 'react-final-form'
import { MenuItem, TextField } from '@material-ui/core'
import {Autocomplete} from "mui-rff";
import countries from './data/countries.json'


export default function CountryField (props) {
  return (
    <Autocomplete
      required={true}
      formControlProps={{ variant: 'outlined' }}
      textFieldProps={{ variant: 'outlined' }}
      options={countries}
      getOptionLabel={(option) => option.label}
      getOptionValue={(option) => option.value}
      // renderInput={(params) => <TextField {...params} label={props.label} variant="outlined" />}
      {...props}
    />
  )
}