import createReduxPromiseListener from 'redux-promise-listener'
import { createStore, applyMiddleware, compose } from 'redux'
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'
// import reducer from './reducer'
import { createBrowserHistory } from 'history'
import { routerMiddleware, connectRouter} from 'connected-react-router'
import { combineReducers } from 'redux'

import baseReducer from './modules/base'

import rootSaga from './sagas'

export const history = createBrowserHistory({})

const routerReducer = connectRouter(history)

const reducers = combineReducers({
  router: routerReducer,
  base: baseReducer
})

const reduxRouterMiddleware = routerMiddleware(history)
const sagaMiddleware = createSagaMiddleware()
const reduxPromiseListener = createReduxPromiseListener()
const middlewares = [reduxRouterMiddleware, sagaMiddleware, reduxPromiseListener.middleware]

// const composeWithDevTools = (
//   process.env.NODE_ENV !== 'production' && typeof window !== 'undefined' &&
//   window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
//     ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
//     : function () {
//       if (arguments.length === 0) return undefined
//       if (typeof arguments[0] === 'object') return compose
//       return compose.apply(null, arguments)
//     }
// )

// const enhancer = applyMiddleware(...middleware);
//
// export function configureStore(initialState) {
//   return createStore(rootReducer, initialState, enhancer);
// }


// const store = createStore(
//   reducer(history),
//   composeWithDevTools(
//     applyMiddleware(...middleware)
//   )
// )
// const store = configureStore({})

const store = configureStore({
  reducer: reducers,
  middleware: [...getDefaultMiddleware(), ...middlewares],
})

export const promiseListener = reduxPromiseListener

export default store

sagaMiddleware.run(rootSaga)