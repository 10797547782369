import { put, call } from 'redux-saga/effects'
import {logout} from '../modules/base'
import {FORM_ERROR} from "final-form";

const getErrorMessage = error => {
  if (!error || !error.body || typeof error.body !== 'object') {
    return ''
  }
  let errorMsg = ''
  const {non_field_errors: nonField, detail, ...fields} = error.body
  if (nonField) {
    errorMsg += nonField.join(', ') + ' '
  }
  if (detail) {
    errorMsg += detail + ' '
  }
  if (fields) {
    Object.keys(fields).forEach(f => {
      errorMsg += fields[f] + ' '
    })
  }
  return errorMsg
}

export function * callApi (apiFn, actionData, actionSuccess, actionFailure) {
  try {
    const data = yield call(apiFn, actionData)
    if (data && actionSuccess) {
      yield put({ type: actionSuccess, result: data, payload: actionData })
    }
    return { res: data }
  } catch (error) {
    let requestErrorMessage = ''
    if (error) {
      requestErrorMessage = getErrorMessage(error.res)
      console.log('API ERROR: ', error, requestErrorMessage, error.res)
      if (error.res && error.res.status === 401) {
        yield put(logout())
      }
    }
    // const errorMsg = getErrorMessage(error.res) || (error && error.message) || error || 'Server issues. Please try again or contact support'
    const errorMsg = requestErrorMessage || 'Server or connection issues. Please try again or contact support.'
    if (actionFailure) {
      yield put({type: actionFailure, error: errorMsg, payload: actionData})
    }
    return { error: errorMsg }
  }
}
