import React, {useState, useCallback} from 'react'
import { Field } from 'react-final-form'
import Wizard from './Wizard'

import { TextField, Select, DatePicker, Checkboxes } from 'mui-rff'
import Grid from "@material-ui/core/Grid";
import { MenuItem } from '@material-ui/core';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import CountryField from "../../form/CountryField";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import {requiredChecked, number, required, requiredAll, setError} from "../../form/validation";
import {INDUSTRIES} from "../../const";

const useStyles = makeStyles(theme => ({
  agreements: {
    marginTop: theme.spacing(4)
  },
  otherAddressStart: {
    marginTop: theme.spacing(2)
  }
}))

const validatePage1 = values => {
  let errors = {}
  errors = requiredAll([
    'firstName', 'lastName', 'hasOtherNames', 'birthCountry', 'dateOfBirth',
    'citizenship', 'idType', 'taxResidency', 'phoneNumber'
  ], values)
  if (values.hasOtherNames === 'Yes') {
    setError(required, 'otherNames', values, errors)
  }
  return errors;
};

const validatePage2 = values => {
  let errors = {}
  const address = values.address || {}
  const addressErrors = requiredAll([
    'line1', 'postcode', 'city', 'country', 'years'
  ], address)
  setError(required, 'employment', values, errors)
  setError(number, 'years', address, addressErrors)
  if (address.years && address.years < 3 && address.otherAddress) {
    const errorsOther =  requiredAll([
      'line1', 'postcode', 'city', 'country', 'years'
    ], address.otherAddress || {})
    setError(number, 'years', address.otherAddress || {}, errorsOther)
    if (JSON.stringify(errorsOther) !== '{}') {
      addressErrors.otherAddress = errorsOther
    }
  }
  if (JSON.stringify(addressErrors) !== '{}') {
    errors.address = addressErrors
  }
  if (['Full-time', 'Self-Employed', 'Student', 'Part-time', 'Self-employed'].includes(values.employment)) {
    setError(required, 'employerName', values, errors)
  }
  if (['Unemployed', 'Retired'].includes(values.employment)) {
    setError(required, 'employmentExplanation', values, errors)
  }
  if (['Full-time', 'Self-Employed', 'Part-time', 'Self-employed'].includes(values.employment)) {
    const errorsEmploymentAddress =  requiredAll([
      'line1', 'postcode', 'city', 'country', 'years'
    ], values.employerAddress || {})
    setError(number, 'years', values.employerAddress || {}, errorsEmploymentAddress)
    const errorsEmployment =  requiredAll([
      'employerPosition', 'employmentIndustry'
    ], values)
    errors = {...errors, ...errorsEmployment}
    if (JSON.stringify(errorsEmploymentAddress) !== '{}') {
      errors.employerAddress = errorsEmploymentAddress
    }
  }
  return errors;
};

const validatePage3 = values => {
  let errors = {}
  errors = requiredAll(['annualIncome', 'sourceOfIncome', 'totalNetWorth', 'securityWord'], values)
  if (values.sourceOfIncome === 'Other') {
    setError(required, 'otherIncome', values, errors)
  }
  setError(number, 'annualIncome', values, errors)
  setError(number, 'totalNetWorth', values, errors)
  setError(requiredChecked, 'agreeData', values, errors)
  return errors;
};

const INDUSTRY_ITEMS = INDUSTRIES.map((v, i) => <MenuItem key={i} value={v}>{v}</MenuItem>)

const PersonalWizard = ({info}) => {
  const [values, setValues] = useState(info.data)
  const classes = useStyles()
  return (
    <Wizard
      initialValues={info.data}
      valuesCallback={setValues}
    >
      <Wizard.Page validate={validatePage1}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={2}>
            <TextField
              autoFocus
              variant="outlined"
              fullWidth
              label="Title"
              name="title"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              variant="outlined"
              fullWidth
              label="First name"
              name="firstName"
              required
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <TextField
              autoFocus
              variant="outlined"
              fullWidth
              label="Middle"
              name="middleName"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              variant="outlined"
              fullWidth
              label="Last name"
              name="lastName"
              required
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Select required name="hasOtherNames" label="Are you known by any other names?" formControlProps={{ variant: 'outlined' }}>
              <MenuItem value="Yes">Yes</MenuItem>
              <MenuItem value="No">No</MenuItem>
            </Select>
          </Grid>
          {values.hasOtherNames === 'Yes' &&
          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              fullWidth
              label="Other Names"
              name="otherNames"
              required
            />
          </Grid>}
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <CountryField
              fullWidth
              label="Place of Birth"
              name="birthCountry"
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DatePicker
              label="Date Of Birth"
              name="dateOfBirth"
              required={true}
              dateFunsUtils={DateFnsUtils}
              inputVariant="outlined"
              format="MMMM d, yyyy"
              disableFuture
              openTo="year"
              views={["year", "month", "date"]}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <CountryField
              fullWidth
              label="Citizenship"
              name="citizenship"
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              fullWidth
              type="tel"
              label="Phone"
              name="phoneNumber"
              required
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Select required name="idType" label="Identity Document Type" formControlProps={{ variant: 'outlined' }}>
              <MenuItem value="Passport">Passport</MenuItem>
              <MenuItem value="EU ID">EU ID</MenuItem>
              <MenuItem value="US Driving License">US Driving License</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              fullWidth
              label="Identity Document #"
              name="idNo"
              required
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <CountryField
              fullWidth
              label="Tax Residency"
              name="taxResidency"
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              fullWidth
              label="Tax Number (if applicable)"
              name="taxNo"
            />
          </Grid>
        </Grid>
      </Wizard.Page>
      <Wizard.Page validate={validatePage2}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              fullWidth
              label="Address Line 1"
              name="address.line1"
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              fullWidth
              label="Address Line 2"
              name="address.line2"
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              autoFocus
              variant="outlined"
              fullWidth
              label="Postcode"
              name="address.postcode"
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              fullWidth
              label="City"
              name="address.city"
              required
            />
          </Grid>

        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <CountryField
              fullWidth
              label="Country"
              name="address.country"
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              variant="outlined"
              fullWidth
              type="number"
              label="Years living here"
              name="address.years"
            />
          </Grid>
        </Grid>
        {values.address && values.address.years < 3 &&
        <Grid container spacing={2} className={classes.otherAddressStart}>
          <Grid item xs={12}>
            <Typography variant="body1" align="center">
              Other Address
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              fullWidth
              label="Address Line 1"
              name="address.otherAddress.line1"
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              fullWidth
              label="Address Line 2"
              name="address.otherAddress.line2"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              autoFocus
              variant="outlined"
              fullWidth
              label="Postcode"
              name="address.otherAddress.postcode"
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              fullWidth
              label="City"
              name="address.otherAddress.city"
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CountryField
              fullWidth
              label="Country"
              name="address.otherAddress.country"
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              variant="outlined"
              fullWidth
              label="Years living here"
              name="address.otherAddress.years"
            />
          </Grid>
        </Grid>}

        <Grid container spacing={2} className={classes.otherAddressStart}>
          <Grid item xs={12}>
            <Typography variant="body1" align="center">
              Employment
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Select
              required
              name="employment"
              label="Employment Status"
              formControlProps={{ variant: 'outlined' }}
            >
              <MenuItem value="Full-time">Full-time</MenuItem>
              <MenuItem value="Part-time">Part-time</MenuItem>
              <MenuItem value="Unemployed">Unemployed</MenuItem>
              <MenuItem value="Self-employed">Self-employed</MenuItem>
              <MenuItem value="Student">Student</MenuItem>
              <MenuItem value="Retired">Retired</MenuItem>
            </Select>
          </Grid>
          {['Full-time', 'Self-Employed', 'Student', 'Part-time', 'Self-employed'].includes(values.employment) &&
          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              fullWidth
              required
              label={values.employment === 'Student' ? 'School Name' : 'Company Name'}
              name="employerName"
            />
          </Grid>}
          {['Unemployed', 'Retired'].includes(values.employment) &&
          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              fullWidth
              required
              label="Please explain the source of funds"
              name="employmentExplanation"
            />
          </Grid>}
          {['Full-time', 'Self-Employed', 'Part-time', 'Self-employed'].includes(values.employment) &&
          <>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              fullWidth
              label="Employer's Address Line 1"
              name="employerAddress.line1"
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              fullWidth
              label="Address Line 2"
              name="employerAddress.line2"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              autoFocus
              variant="outlined"
              fullWidth
              label="Postcode"
              name="employerAddress.postcode"
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              fullWidth
              label="City"
              name="employerAddress.city"
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CountryField
              fullWidth
              label="Country"
              name="employerAddress.country"
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              variant="outlined"
              type="number"
              fullWidth
              label="Years employed here"
              name="employerAddress.years"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              fullWidth
              label="Position"
              name="employerPosition"
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Select
              required
              name="employmentIndustry"
              label="Industry"
              formControlProps={{ variant: 'outlined' }}
            >
              {INDUSTRY_ITEMS}
            </Select>
          </Grid>
          </>}
        </Grid>
      </Wizard.Page>

      <Wizard.Page validate={validatePage3}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              fullWidth
              label="Security Word (Mother's Maiden Name)"
              name="securityWord"
              required
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              fullWidth
              type="number"
              label="Total Net Worth"
              name="totalNetWorth"
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              fullWidth
              type="number"
              label="Annual Income"
              name="annualIncome"
              required
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Select
              required
              name="sourceOfIncome"
              label="Source Of Income"
              formControlProps={{ variant: 'outlined' }}
            >
              <MenuItem value="Salary">Salary</MenuItem>
              <MenuItem value="Rental Income">Rental Income</MenuItem>
              <MenuItem value="Dividends">Dividends</MenuItem>
              <MenuItem value="Other">Other</MenuItem>
            </Select>
          </Grid>
          {values.sourceOfIncome === 'Other' &&
          <Grid item xs={12} sm={6}>
            <TextField
              variant="outlined"
              fullWidth
              label="Please Specify Other Source"
              name="otherIncome"
              required
            />
          </Grid>}
        </Grid>
        <Grid container spacing={2} className={classes.agreements}>
          <Grid item xs={12}>
            <Checkboxes
              label=""
              color="primary"
              name="agreeData"
              required
              data={{label: 'I consent to use the data provided in order to check and create an account for me', value: false}}
            />
          </Grid>
        </Grid>
      </Wizard.Page>
    </Wizard>
  )
}

export default PersonalWizard