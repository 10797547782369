import isEmail from 'validator/lib/isEmail';

function isDirty(value) {
  return value || value === 0;
}

export const required = value => (isDirty(value) ? undefined : "Required")

export const number = value => (isNaN(value) ? "Must be a number" : undefined)

export const requiredChecked = value => (value !== true ? 'Should be checked' : undefined)

export const minValue = min => value =>
  isNaN(value) || value >= min ? undefined : `Should be greater than ${min}`

export const cV = (...validators) => value =>
  validators.reduce((error, validator) => error || validator(value), undefined);


export function email(value) {
  return value && !isEmail(value.trim()) ? 'Invalid email' : null;
}

export function password(value) {
  return value && value.length < 8 ? 'Minimum length is 8 characters' : null
}

export function setError(fn, field, values, errors) {
  const err = fn(values[field])
  if (err) {
    errors[field] = err
  }
}



export function requiredAll(requiredFields, values) {
  return requiredFields.reduce(
    (fields, field) => ({
      ...fields,
      ...(isDirty(values[field]) ? undefined : { [field]: 'Required' }),
    }),
    {},
  );
}
