import React from 'react'
import { Route } from 'react-router-dom'

const PublicRoute = ({ config, logging, authenticated, component, ...rest }) => (
  <Route {...rest} render={(props) => {
    return (React.createElement(component, { ...props, config, logging, authenticated }))
    // return !authenticated
    //   ? (React.createElement(component, { ...props, loggingIn, authenticated }))
    //   : (<Redirect to={{pathname: '/dashboard'}} />)
  }} />
)

export default PublicRoute