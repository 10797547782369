import {
  REGISTER, REGISTER_SUCCESS, REGISTER_FAIL, REGISTER_FORM,
  LOGIN, LOGIN_SUCCESS, LOGIN_FAIL, FORM_LOGIN,
  AUTH, AUTH_SUCCESS, AUTH_FAIL,
  LOGOUT, LOGOUT_SUCCESS, LOGOUT_FAIL,
  DASHBOARD, DASHBOARD_SUCCESS, DASHBOARD_FAIL,
  SUBMIT, SUBMIT_SUCCESS, SUBMIT_FAIL,
  UPFILE, UPFILE_SUCCESS, UPFILE_FAIL,
  ACTIVITY_CHECK, ACTIVITY_LOGOUT_STOP, ACTIVITY_CHECK_STOP, ACTIVITY_LOGOUT
} from '../modules/base'

import {take, call, put, fork, select, spawn, race, delay } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { toast } from 'react-toastify';

import {callApi} from './utils'
import api, {setToken, removeToken} from '../../api'
import {PERSONAL_ROUTE, BUSINESS_ROUTE, LOGIN_ROUTE} from "../../const";

const getStoreUser = state => state.base.user

function * postLogin (data) {
  setToken(data.token)
  // yield put(setData(data))
  // yield put(push(data.next))
  yield put({type: ACTIVITY_CHECK})
  if (data.user.account_type === 'business') {
    yield put(push(BUSINESS_ROUTE))
  } else {
    yield put(push(PERSONAL_ROUTE))
  }
}

export function * register (data) {
  const company = yield select(state => state.base.company)
  return yield callApi(api.base.register, {company, ...data}, REGISTER_SUCCESS, REGISTER_FAIL)
}

export function * registerFlow () {
  while (true) {
    let request = yield take(REGISTER)

    console.log('got register')
    let {res, error} = yield call(register, request.payload)
    if (res && res.token && res.token.length > 10 && !error) {
      yield postLogin(res)
    }
  }
}

export function * login (data) {
  return yield callApi(api.base.login, data, LOGIN_SUCCESS, LOGIN_FAIL)
}

export function * loginFlow () {
  while (true) {
    let request = yield take(LOGIN)
    let {res, error} = yield call(login, request.payload)
    if (res && res.token && res.token.length > 10 && !error) {
      yield postLogin(res)
    }
  }
}

export function * authorizeWithToken () {
  const {res, error} = yield callApi(api.base.authorizeWithToken, {}, AUTH_SUCCESS, AUTH_FAIL)
  if (res && res.token && res.token.length > 10 && !error) {
    return res
  } else {
    return false
  }
}

export function * authorizeWithTokenFlow () {
  while (true) {
    yield take(AUTH)
    let success = yield call(authorizeWithToken)
    if (success) {
      yield postLogin(success)
    }
  }
}

export function * logout (remoteLogout=false) {
  yield put({type: ACTIVITY_CHECK_STOP})
  if (remoteLogout) {
    yield callApi(api.base.logout, {}, LOGOUT_SUCCESS, LOGOUT_FAIL)
  } else {
    yield put({type: LOGOUT_SUCCESS})
  }
  removeToken()
  yield put(push(LOGIN_ROUTE))
  toast.error('Logged out.')
}

export function * logoutFlow () {
  while (true) {
    const request = yield take(LOGOUT)
    yield call(logout, request.payload.remoteLogout)
  }
}


export function * dashboard (data) {
  const {res} = yield callApi(api.base.dashboard, data, DASHBOARD_SUCCESS, DASHBOARD_FAIL)
  if (res) {
    return res
  } else {
    return false
  }
}

export function * dashboardFlow () {
  while (true) {
    let request = yield take(DASHBOARD)
    let success = yield call(dashboard, request.payload)
    if (success && success.currentAccount && success.currentAccount.next) {
      if (request.payload && request.payload.first) {
        yield put(push('/' + success.currentAccount.next))
      }
    }
  }
}


export function * submit (data) {
  const {res, error} = yield callApi(api.base.submit, data, SUBMIT_SUCCESS, SUBMIT_FAIL)
  if (res) {
    return res
  } else {
    return false
  }
}

export function * submitFlow () {
  while (true) {
    let request = yield take(SUBMIT)
    yield call(submit, request.payload)
  }
}

export function * uploadFile (data) {
  yield callApi(api.base.uploadFile, data, UPFILE_SUCCESS, UPFILE_FAIL)
}

export function * uploadFileFlow () {
  while (true) {
    let request = yield take(UPFILE)
    yield call(uploadFile, request.payload)
  }
}

function * activityCheck() {

  const {res, error} = yield callApi(api.base.checkToken)
  if (error || !res) {
    yield call(logout)
    return
  }
  yield put({type: ACTIVITY_LOGOUT})
  const { stop_logout, do_logout } = yield race({
    stop_logout: take(ACTIVITY_LOGOUT_STOP),
    do_logout: delay(30000)
  })
  if (do_logout) {
    yield put({type: ACTIVITY_LOGOUT_STOP})
    yield call(logout)
  } else if (stop_logout) {
    yield callApi(api.base.extendToken)
  }
}

export function * activityCheckFlow () {
  while (true) {
    yield take(ACTIVITY_CHECK)

    while (true) {
      const { stop_check, do_check } = yield race({
        stop_check: take(ACTIVITY_CHECK_STOP),
        do_check: delay(60000 * 30)
      })

      let user = yield select(getStoreUser)
      if (stop_check || !user) {
        break
      } else if (do_check) {
        yield fork(activityCheck)
      }
    }
  }
}

export default function * root () {
  yield fork(registerFlow)
  yield fork(loginFlow)
  yield fork(authorizeWithTokenFlow)
  yield fork(logoutFlow)
  yield fork(dashboardFlow)
  yield fork(submitFlow)
  yield fork(uploadFileFlow)
  yield fork(activityCheckFlow)
}
