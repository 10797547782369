
let windowEnv = {}

if (typeof window !== 'undefined') {
  windowEnv = window
}

const environment = {
  development: {
    isProduction: false
  },
  production: {
    isProduction: true
  }
}[process.env.NODE_ENV || 'development']


module.exports = Object.assign({
  ownHost: process.env.REACT_APP_OWNHOST || 'localhost:3000',
  host: process.env.HOST || 'localhost',
  port: process.env.PORT,
  apiHost: process.env.REACT_APP_APIHOST || 'localhost',
  apiPort: process.env.REACT_APP_APIPORT || 8000,
  apiPrefix: process.env.REACT_APP_APIPREFIX || '/v1/api',
  sumsubHost: process.env.REACT_APP_SUMSUB_HOST || 'https://test-api.sumsub.com',
  bizWidgetId: process.env.REACT_APP_BUSINESS_WIDGET_ID || '',
  window: windowEnv,
}, environment)
