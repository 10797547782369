import {FORM_ERROR} from "final-form"


export const formError = (data) => {
  const errors = data.error || {}
  const nfe = errors.non_field_errors || []
  if (nfe.length > 0) {
    errors[FORM_ERROR] = nfe.join(', ')
  }
  return errors
}