import apiClient from './apiClient'
import {removeEmpty} from './utils'

export function register (formData) {
  //const data = removeEmpty(removeEmpty({...formData}))
  const data = removeEmpty({...formData})
  return apiClient.post('auth/register/', {data})
}

export function login (data) {
  return apiClient.post('auth/login/', {data})
}

export function authorizeWithToken () {
  return apiClient.get('auth/auth-token/')
}

export function checkToken () {
  return apiClient.get('auth/check-token/')
}

export function extendToken () {
  return apiClient.get('auth/extend-token/')
}

export function logout (data) {
  return apiClient.post('auth/logout/', {data})
}

export function getTicket () {
  return apiClient.get('auth/sign/')
}


export function dashboard (data) {
  return apiClient.get('client/dashboard/')
}

export function submit (formData) {
  const data = removeEmpty({...formData})
  return apiClient.post('client/submit/', {data})
}

export function uploadFile (data) {
  return apiClient.post('client/upload/', data)
}

export function getSumsubToken () {
  return apiClient.post('client/sumsub-personal-token/', {})
}