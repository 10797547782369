import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'react-final-form'
import { withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import MakeAsyncFunction from "react-redux-promise-listener";
import {promiseListener} from "../../redux/store";
import {SUBMIT, SUBMIT_FAIL, SUBMIT_SUCCESS} from "../../redux/modules/base";
import memoize from "../../utils/memoize";


const styles = theme => ({
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
});

function toJSONLocal (date) {
  var local = new Date(date);
  local.setMinutes(date.getMinutes() - date.getTimezoneOffset());
  return local.toJSON().slice(0, 10);
}

const steps = ['Details', 'Address', 'Agreements'];

class Wizard extends React.Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired
  }
  static Page = ({ children }) => children

  constructor(props) {
    super(props)
    this.state = {
      page: 0,
      values: props.initialValues || {}
    }
    this.valuesCallback = memoize(props.valuesCallback)
  }
  next = values =>
    this.setState(state => ({
      page: Math.min(state.page + 1, this.props.children.length - 1),
      values
    }))

  previous = () =>
    this.setState(state => ({
      page: Math.max(state.page - 1, 0)
    }))


  validate = values => {

    const activePage = React.Children.toArray(this.props.children)[
      this.state.page
      ]
    this.valuesCallback(values)
    return activePage.props.validate ? activePage.props.validate(values) : {}
  }

  validate2 = page => values => {
    const activePage = React.Children.toArray(this.props.children)[page]
    return activePage.props.validate ? activePage.props.validate(values) : {}
  }

  handleSubmit = onSubmit => values => {
    const { children } = this.props
    const { page } = this.state
    const isLastPage = page === React.Children.count(children) - 1
    if (isLastPage) {
      const dateValues = {...values}
      if (dateValues.dateOfBirth) {
        dateValues.dateOfBirth = toJSONLocal(values.dateOfBirth)
      }
      return onSubmit(dateValues)
    } else {
      this.next(values)
    }
  }

  render() {
    const { children, classes } = this.props
    const { page, values } = this.state
    const activePage = React.Children.toArray(children)[page]
    const isLastPage = page === React.Children.count(children) - 1
    return (
      <MakeAsyncFunction
        listener={promiseListener}
        start={SUBMIT}
        resolve={SUBMIT_SUCCESS}
        reject={SUBMIT_FAIL}
      >
        {onSubmit => (
          <Form
            initialValues={values}
            validate={(values) => this.validate(values)}
            // validate={this.validate2(page)}
            onSubmit={this.handleSubmit(onSubmit)}
            render={({handleSubmit, submitting, values}) => (
              <form onSubmit={handleSubmit} noValidate>
                <Typography component="h1" variant="h4" align="center">
                  Personal Details
                </Typography>
                <Stepper activeStep={page} className={classes.stepper}>
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
                {activePage}
                <div className={classes.buttons}>
                  {page !== 0 && (
                    <Button onClick={this.previous} className={classes.button}>
                      Back
                    </Button>
                  )}
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={submitting && isLastPage}
                    className={classes.button}
                    type="submit"
                  >
                    {page === steps.length - 1 ? 'Submit' : 'Next'}
                  </Button>
                </div>
                {isLastPage && false &&
                'submitError'
                }
              </form>
            )}
          />)
        }
      </MakeAsyncFunction>
    )
  }
}

export default withStyles(styles)(Wizard)