import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Logo from "./assets/long.png";
import {useDispatch} from "react-redux";
import {logout} from "../redux/modules/base";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        Recary Ltd
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

function Agreement() {
  return (
    <Typography variant="body2"  align="center">
      {'Our '}
      <Link target="_blank" href="https://recary.co.uk/privacy-policy">
        Privacy Policy
      </Link>
      {' and '}
      <Link target="_blank" href="https://recary.co.uk/terms-conditions">
        Terms & Conditions
      </Link>{'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    position: 'relative',
  },
  toolbar: {
    flexWrap: 'wrap',
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  logo: {
    height: 50
  },
  logoContainer: {
    flexGrow: 1,
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(800 + theme.spacing(2) * 2)]: {
      width: 800,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(800 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  footer: {
    padding: theme.spacing(3, 2),
    marginTop: 'auto',
  },
}));


export default function PrivateLayout({children}) {
  const classes = useStyles();
  const dispatch = useDispatch()
  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar position="static" color="default" elevation={0} className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <div className={classes.logoContainer}>
            <img className={classes.logo} src={Logo} alt="Logo" />
          </div>
          <Button onClick={(e) => {dispatch(logout(true))}} color="primary" variant="outlined" className={classes.link}>
            Logout
          </Button>
        </Toolbar>
      </AppBar>
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          {children}
        </Paper>

      </main>
      <footer className={classes.footer}>
        <Agreement />
        <Copyright />
      </footer>
    </React.Fragment>
  );
}