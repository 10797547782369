import React, {useState, useEffect} from 'react'
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import snsWebSdk from '@sumsub/websdk';
import api from '../../api'



const useStyles = makeStyles(theme => ({
  root: {
    minHeight: 400
  },
  header: {
    marginBottom: theme.spacing(2)
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  container: {
    marginBottom: theme.spacing(2)
  }
}))

const Verification = ({config, info, user}) => {
  const classes = useStyles()
  const [isChecking, setIsChecking] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const launchWebSdk = (accessToken) => {
    let snsWebSdkInstance = snsWebSdk
      .init(
        accessToken,
        (newAccessTokenCallback) => {
          const getNewToken = async () => {
            try {
              const result = await api.base.getSumsubToken();
              newAccessTokenCallback(result.token)
            } catch (e) {
              console.log('New token error ', e)
            }
          }
          getNewToken()
        }
      )
      .withConf({
        lang: 'en',
        email: user.email,
        phone: info.phoneNumber || ''
      })
      .on('idCheck.onApplicantLoaded', (payload) => {
        setIsChecking(true)
      })
      .on('idCheck.onApplicantSubmitted', (payload) => {
        setIsSubmitted(true)
      })
      .on('idCheck.onError', (error) => {
        console.error('WebSDK onError', error)
      })
      .build();
    snsWebSdkInstance.launch('#sumsub-websdk-container-321')
  }
  useEffect(() => {
    const launchSumSub = async () => {
      if (info.isCheckInit) {
        setIsChecking(true)
        try {
          const result = await api.base.getSumsubToken();
          launchWebSdk(result.token)
        } catch (e) {
          console.log('New token error ', e)
        }
      }
    };
    launchSumSub();
  }, []);
  const onButtonClick = async () => {
    try {
      const result = await api.base.getSumsubToken();
      launchWebSdk(result.token)
    } catch (e) {
      console.log('New token error ', e)
    }
  }
  return (
    <div className={classes.root}>
      <Typography component="h1" variant="h4" align="center" className={classes.header}>
        Verification
      </Typography>
      {(!info.isCheckCompleted && !isChecking) &&
      <Container maxWidth="sm" className={classes.container}>
        <Typography variant="h5" align="center" color="textSecondary" paragraph>
          In order to continue with the application you have to pass the KYC check and verify your ID. <br />
          If you have any questions about the check you can read the guide&nbsp;
          <a target="_blank" rel="noreferrer" href="https://sumsub.com/how-to-pass-verification">here</a>.
        </Typography>
        <div className={classes.heroButtons}>
          <Grid container spacing={2} justify="center">
            <Grid item>
              <Button variant="contained" color="primary" onClick={onButtonClick}>
                Run the Check
              </Button>
            </Grid>
          </Grid>
        </div>
      </Container>
      }
      {(info.isCheckCompleted || isSubmitted) &&
      <Container maxWidth="sm" className={classes.container}>
        <Typography variant="h5" align="center" color="textSecondary" paragraph>
          Thank you for completing the verification process. Our team will contact you shortly.
        </Typography>
      </Container>
      }
      <div id="sumsub-websdk-container-321" />
    </div>
  )
}

export default Verification