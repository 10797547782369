import React from 'react';
import { Provider } from 'react-redux'

import {authorizeWithToken} from './redux/modules/base'
import {findToken, saveToken, getUrlParameters} from './api'

import { ConnectedRouter } from 'connected-react-router'
import Routes from './Routes'
import store, {history} from './redux/store'
import {setCompany} from "./redux/modules/base";
import 'react-toastify/dist/ReactToastify.css';


if (typeof (window) !== 'undefined') {
  const loc = window.location
  // const path = loc ? loc.pathname : null
  // if (path && path !== '/' && path !== '/login' && path !== '/register') {
  //   store.dispatch(setPath(path))
  // }
  const urlParameters = getUrlParameters(loc.href)
  if (urlParameters.company) {
    store.dispatch(setCompany(urlParameters.company))
  }
  let savedToken = findToken(loc.href)
  if (savedToken) {
    saveToken(savedToken)
    store.dispatch(authorizeWithToken())
  }
}

function App() {
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Routes />
      </ConnectedRouter>
    </Provider>
  );
}

export default App;
