import React, {useState, useEffect} from 'react'

import Typography from '@material-ui/core/Typography';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { withStyles } from '@material-ui/core/styles';
import PrivateLayout from "../../layout/PrivateLayout";
import axios from 'axios'
import Alert from '@material-ui/lab/Alert';
import {formatUrl} from "../../api"
import FormWidget from '../../form/FormWidget';


const styles = theme => ({
  root: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'stretch',
  },
  // stepper: {
  //   maxWidth: 600,
  //   margin: 'auto'
  // }
})




function Business ({classes, config, client}) {
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const widgetUrl = 'client/widget/' + config.bizWidgetId + '/'

  useEffect(() => {
    const fetchData = async () => {
      setIsError(false);
      setIsLoading(true);

      try {

        const result = await axios.post(formatUrl(widgetUrl, config), {})
        setData(result.data);


      } catch (error) {
        setIsError(true);
      }
      setIsLoading(false);
    };
    fetchData();
  }, [config.bizWidgetId]);

  const handleSubmit = async (formData) => {
    const submitData = {
      form: formData
    }
    const result = await axios.post(formatUrl(widgetUrl + 'submit-data/', config), submitData)
    setData(result.data);
  }

  return (
    <PrivateLayout>
      <div className={classes.root}>
        <div style={{marginRight: 12}}>
          {isError && (
            <Alert variant="danger">
              <Typography variant="h5">Error</Typography>
              <p>Please contact support.</p>

            </Alert>
          )}

          {isLoading && (
            <Alert variant="info">
              <Typography variant="h5">Loading...</Typography>
              <p>
                The questionnaire is loading.
              </p>
            </Alert>
          )}
          {!isError && !isError && data.form && !data.isCompleted && (
            <FormWidget form={data.form} submitHandler={handleSubmit} />
          )}
          {data.isCompleted && (!data.useSumsub || data.isSumsubCompleted)  && (
            <Alert variant="success">
              <Typography variant="h5">Thank you!</Typography>
              <p>
                You have completed the form.
              </p>
            </Alert>
          )}
      </div>
      </div>
    </PrivateLayout>
  )
}

export default withStyles(styles)(Business)