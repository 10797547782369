import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Form from '@rjsf/material-ui';
import cloneDeep from 'lodash.clonedeep'


const FormWidget = ({form, submitHandler}) => {
  const [step, setStep] = useState(0)
  const [totalData, setTotalData] = useState({})

  const {pages, definitions} = form
  const onChange = ({formData}) => {
    setTotalData({
      ...cloneDeep(formData),
      // ...cloneDeep(totalData)
    })
  }
  const onSubmit = ({formData}) => {
    if (step < pages.length - 1) {
      setStep(step + 1)
      setTotalData({
        ...cloneDeep(formData),
        ...cloneDeep(totalData)
      })
    } else {
      submitHandler(formData)
    }
  }

  const getSchema = (currentStep) => (
    {
      definitions: definitions,
      ...pages[currentStep].schema
    }
  )
  const getUiSchema = (currentStep) => pages[currentStep].uischema
  const previousStep = () => step !== 0 && setStep(step - 1)
  const getSubmit = (currentStep) => {
    if (currentStep === pages.length - 1) {
      return (
        <Grid container>
          <Grid item>
            <Button type="submit" onClick={previousStep} variant="secondary" size="lg" block>
              Previous
            </Button>
          </Grid>
          <Grid item>
            <Button type="submit" variant="primary" size="lg" block>
              Submit
            </Button>
          </Grid>
        </Grid>
      )
    } else if (currentStep === 0) {
      return (
        <Grid container>
          <Grid item />
          <Grid item>
            <Button type="submit" variant="primary" size="lg" block>
              Next
            </Button>
          </Grid>
        </Grid>
      )
    } else {
      return (
        <Grid container>
          <Grid item>
            <Button type="submit" onClick={previousStep} variant="secondary" size="lg" block>
              Previous
            </Button>
          </Grid>
          <Grid item>
            <Button type="submit" variant="primary" size="lg" block>
              Next
            </Button>
          </Grid>
        </Grid>
      );
    }
  }

  return (
    <Form
      schema={getSchema(step)}
      onSubmit={onSubmit}
      onChange={onChange}
      formData={totalData}
      uiSchema={getUiSchema(step)}
      showErrorList={false}
    >
      {getSubmit(step)}
    </Form>
  )
}

export default FormWidget
