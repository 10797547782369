import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import {PERSONAL, BUSINESS, PERSONAL_ROUTE, BUSINESS_ROUTE} from "../const";


const PrivateRoute = ({ logging, authenticated, component, user, config, info, accountRoutes, isPersonal, isBusiness, ...rest }) => (
  <Route {...rest} render={(props) => {
    if (logging) return <div>Logging in...</div>
    if (authenticated) {
      if (isPersonal && user.accountType !== PERSONAL) {
        return (<Redirect to={{pathname: BUSINESS_ROUTE, state: { from: props.location }}} />)
      }
      if (isBusiness && user.accountType !== BUSINESS) {
        return (<Redirect to={{pathname: PERSONAL_ROUTE, state: { from: props.location }}} />)
      }
      return (React.createElement(component, { ...props, user, info, logging, authenticated, config }))
    }
    return (<Redirect to={{pathname: '/', state: { from: props.location }}} />)
  }} />
)

export default PrivateRoute
