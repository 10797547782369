import React from 'react'
import { Switch } from 'react-router-dom'

import CssBaseline from '@material-ui/core/CssBaseline';
import theme from './theme';
import {Login, Register, Personal, Business} from './screens'
import {ThemeProvider} from '@material-ui/styles'
import {withStyles} from '@material-ui/core'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import config from './config'
import { connect } from 'react-redux'
import { HomeRoute, PublicRoute, PrivateRoute } from './components'
import { ToastContainer } from 'react-toastify';

import * as baseActions from './redux/modules/base'
import {LOGIN_ROUTE, REGISTER_ROUTE, PERSONAL_ROUTE, BUSINESS_ROUTE} from "./const";



const styles = theme => ({
  root: {
    display: 'flex',
    minHeight: "100vh",
    flexDirection: 'column',

  },
  container: {
    display: 'flex',
    flex: 1
  },
  paper: {
    display: 'flex',
    flex: 1,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    padding: theme.spacing(4, 3),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(8, 6),
    },
  },
})

function Routes({classes, ...props}) {

  const {
    user, info, loggingIn, registeringIn, authIn, logout, checkDialog, activityLogoutStop
  } = props
  const authenticated = !!user
  const logging = loggingIn || registeringIn || authIn
  const routeProps = {authenticated, logging, config, user, info}
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className={classes.root}>
        <Switch>
          <HomeRoute exact path='/' component={ Register } {...routeProps} />
          <PublicRoute exact path={LOGIN_ROUTE} component={ Login } {...routeProps} />
          <PublicRoute exact path={REGISTER_ROUTE} component={ Register } {...routeProps} />
          <PrivateRoute exact path={PERSONAL_ROUTE} component={ Personal } isPersonal {...routeProps} />
          <PrivateRoute exact path={BUSINESS_ROUTE} component={ Business } isBusiness {...routeProps} />
        </Switch>
        <Dialog open={checkDialog} onClose={activityLogoutStop}>
          <DialogTitle id="alert-dialog-title">Session expiration</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Your session is about to expire. If you don't want to continue you will be logged out in 30 seconds.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={activityLogoutStop} color="primary" autoFocus>
              Continue
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </ThemeProvider>
  )
}

export default connect(
  state => ({
    user: state.base.user,
    info: state.base.info || {},
    loggingIn: state.base.loggingIn,
    registeringIn: state.base.registeringIn,
    authIn: state.base.authIn,
    checkDialog: state.base.checkDialog,
  }),
  { ...baseActions }
)(withStyles(styles)(Routes))

// <AppAppBar authenticated={authenticated} handleLogout={logout} />
// <Container maxWidth="md" className={classes.container}>
// <Paper className={classes.paper}>
// </Paper>
// </Container>
// <AppFooter />